import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'

const IOScaling = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'navigation.ioScalingLink' })} />
    <form>
      <div
        style={{
          maxWidth: `800px`,
          margin: `0 auto`,
        }}
      >
        <div className='interfaceRow'>
          <div className='quart'>VOC:</div>
          <div className='quart inputfields'>
            <label htmlFor='vocmin'>Min:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='vocmin'
              id='vocmin'
            />
          </div>
          <div className='quart inputfields'>
            <label htmlFor='vocmax'>Max:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='vocmax'
              id='vocmax'
            />
          </div>
          <div className='quart'>Current Value: xxxx</div>
        </div>
        <div className='interfaceRow'>
          <div className='quart'>Humidity:</div>
          <div className='quart inputfields'>
            <label htmlFor='humidmin'>Min:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='humidmin'
              id='humidmin'
            />
          </div>
          <div className='quart inputfields'>
            <label htmlFor='humidmax'>Max:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='humidmax'
              id='humidmax'
            />
          </div>
          <div className='quart'>Current Value: xxxx</div>
        </div>
        <div className='interfaceRow'>
          <div className='quart'>Temperature:</div>
          <div className='quart inputfields'>
            <label htmlFor='tempmin'>Min:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='tempmin'
              id='tempmin'
            />
          </div>
          <div className='quart inputfields'>
            <label htmlFor='tempmax'>Max:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='tempmax'
              id='tempmax'
            />
          </div>
          <div className='quart'>Current Value: xxxx</div>
        </div>
        <div className='interfaceRow'>
          <div className='quart'>CO2:</div>
          <div className='quart inputfields'>
            <label htmlFor='co2min'>Min:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='co2min'
              id='co2min'
            />
          </div>
          <div className='quart inputfields'>
            <label htmlFor='co2max'>Max:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='co2max'
              id='co2max'
            />
          </div>
          <div className='quart'>Current Value: xxxx</div>
        </div>
        <div className='interfaceRow'>
          <div className='quart'>Differential Pressure:</div>
          <div className='quart inputfields'>
            <label htmlFor='diffmin'>Min:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='diffmin'
              id='diffmin'
            />
          </div>
          <div className='quart inputfields'>
            <label htmlFor='diffmax'>Max:</label>
            <input
              className='maxFive'
              type='number'
              placeholder='xxxx'
              name='diffmax'
              id='diffmax'
            />
          </div>
          <div className='quart'>Current Value: xxxx</div>
        </div>
        <div className='spacer'></div>
        <div className='interfaceRow'>
          <div className='half'>Filter Change % Setpoint:</div>
          <div className='quart'>xxxx</div>
          <div className='quart'>Current Value: xxxx</div>
        </div>
        <div className='interfaceRow'>
          <div className='half'>Filter Change Runtime Hour Setpoint:</div>
          <div className='quart'>xxxx</div>
          <div className='quart'>Current Value: xxxx</div>
        </div>
        <div className='interfaceRow flexEnd'>
          <button>Save</button>
        </div>
      </div>
    </form>
  </>
)

export default injectIntl(IOScaling)
